.toggle-switch {
    position: relative;
    width: 60px;
    display: inline-block;
    vertical-align: middle;
  }
  
  .toggle-switch-checkbox {
    display: none;
  }
  
  .toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #999999;
    border-radius: 20px;
    background-color: #FFFFFF;
  }
  
  .toggle-switch-inner {
    width: 200%;
    margin-left: -80%;
    transition: margin 0.2s ease-in 0s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0px;
    height: 20px;
    box-sizing: border-box;
  }
  
  .toggle-switch-inner:before,
  .toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 14px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
  }
  
  .toggle-switch-inner:before {
    content: "ON";
    padding-left: 0px;
    background-color: #34A7C1;
    margin-left: -8px;
    color: #FFFFFF;
  }
  
  .toggle-switch-inner:after {
    content: "OFF";
    padding-right: 20px;
    background-color: #EEEEEE;
    color: #999999;
    text-align: right;
  }
  
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
  }
  
  .toggle-switch-switch {
    display: block;
    width: 18px;
    margin: 1px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 41px;
    border: 2px solid #999999;
    border-radius: 20px;
    transition: all 0.2s ease-in 0s; 
  }
  
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 0px; 
  }
  