/* Calendrier.css */
body {
    -webkit-user-select: none; /* Chrome, Safari, Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  }

  .case-vide:before {
    content: "+";

  }


table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 0.5rem;
  text-align: center;
}

th {
  font-weight: bold;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

td {
  background-color: #fff;
  border: 1px solid #dee2e6;
}



tr:first-child td {
  font-weight: bold;
  text-align: center;
}

tr:first-child td:first-child {
  width: 10%;
}

tr:first-child td:not(:first-child) {
  width: 18%;
}

tr:not(:first-child) td:first-child {
  font-weight: bold;
  text-align: left;
  width: 10%;
}

@media (max-width: 767.98px) {
  tr:first-child td:not(:first-child) {
    width: 25%;
  }
  
  tr:not(:first-child) td:first-child {
    width: 30%;
  }

  /* Appliquer un style différent à chaque groupe de 3 colonnes */
  tr:not(:first-child) td:nth-child(3n+1), 
  tr:not(:first-child) td:nth-child(3n+2), 
  tr:not(:first-child) td:nth-child(3n+3) {
    width: 32%;
  }
}




.table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}




  td.vert {
    background-color: #f5f5f5 !important; /* couleur de fond gris très blanc */
    /*border: 1px solid #00ff00 !important;  
    /*border-left: 1px solid #00ff00 !important;*/
  }

  .participants-count {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.8rem;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 2px 4px;
  }

  .delete-icon {
    margin-left: 5px;
    cursor: pointer;
    color: red;
  }

  @keyframes gradient-animation {
    0% {
      background-image: linear-gradient(to right, #007bff 0%, #007bff 50%, #ffc107 50%, #ffc107 100%);
    }
    50% {
      background-image: linear-gradient(to right, #ffc107 0%, #ffc107 50%, #007bff 50%, #007bff 100%);
    }
    100% {
      background-image: linear-gradient(to right, #007bff 0%, #007bff 50%, #ffc107 50%, #ffc107 100%);
    }
  }
  
  .btn-animated {
    background-size: 200% 100%;
    animation: gradient-animation 2s linear infinite;
    transition: background-position 2s;
  }