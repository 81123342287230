.triangle {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-size: cover;
  width: 47px;
  height: 47px;
  position: absolute;
  right: 0px;
  top:0px;
  padding-left: 20px;
padding-top: 20px;
display: flex;
  flex-direction: column;
  justify-content: flex-end;

  }

  